'use client';
import React from 'react';
import Script from 'next/script';
import { useClientReady } from '@/shared/lib/hooks/use-client-ready';

export type GTMParams = {
  gtmId: string;
};

declare global {
  interface Window {
    dataLayer: object[];
  }
}

export function GoogleTagManager({ gtmId }: GTMParams) {
  const ready = useClientReady();

  if (!ready) {
    return null;
  }

  return (
    <>
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${gtmId}`} />
      <Script
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtmId}');`
        }}
      />
    </>
  );
}

export function YandexMetrics({ ymId }: { ymId: string }) {
  const ready = useClientReady();

  if (!ready) {
    return null;
  }

  return (
    <>
      <Script
        id="ymScript"
        dangerouslySetInnerHTML={{
          __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                   m[i].l=1*new Date();
                   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
                   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
                   ym("${ymId}", "init", {
                        clickmap:true,
                        trackLinks:true,
                        accurateTrackBounce:true,
                        webvisor:true,
                        ecommerce:"dataLayer"
                   });`
        }}
      />
      <noscript>
        <div>
          <img
            src={`https://mc.yandex.ru/watch/${ymId}`}
            style={{ position: 'absolute', left: '-9999px' }}
            alt=""
          />
        </div>
      </noscript>
    </>
  );
}
