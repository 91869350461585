import { gql } from '@apollo/client';

export const registerUserByEmail = gql`
  mutation RegisterUserByEmail(
    $email: String!
    $code: String!
    $password: String!
    $deviceId: String!
    $trackingTags: TrackingTags
  ) {
    registerUserByEmail(
      email: $email
      code: $code
      password: $password
      deviceId: $deviceId
      trackingTags: $trackingTags
    ) {
      success
      message
      accessToken
      error {
        message
        stack
      }
    }
  }
`;

export const registerUserByPhone = gql`
  mutation RegisterUserByPhone(
    $phone: String!
    $code: String!
    $password: String!
    $deviceId: String!
    $trackingTags: TrackingTags
  ) {
    registerUserByPhone(
      phone: $phone
      code: $code
      password: $password
      deviceId: $deviceId
      trackingTags: $trackingTags
    ) {
      success
      message
      accessToken
      error {
        message
        stack
      }
    }
  }
`;

export const registerUnconfirmedByPhone = gql`
  mutation RegisterUnconfirmedByPhone(
    $phone: String!
    $password: String!
    $deviceId: String!
    $trackingTags: TrackingTags
  ) {
    registerUnconfirmedByPhone(
      phone: $phone
      password: $password
      deviceId: $deviceId
      trackingTags: $trackingTags
    ) {
      success
      message
      accessToken
      isNewUser
      user {
        id
      }
      error {
        message
        stack
      }
    }
  }
`;

export const registerUnconfirmedByEmail = gql`
  mutation RegisterUnconfirmedByEmail(
    $email: String!
    $password: String!
    $deviceId: String!
    $trackingTags: TrackingTags
  ) {
    registerUnconfirmedByEmail(
      email: $email
      password: $password
      deviceId: $deviceId
      trackingTags: $trackingTags
    ) {
      success
      message
      user {
        id
      }
      accessToken
      isNewUser
      error {
        message
        stack
      }
    }
  }
`;
