'use client';

import React from 'react';
import Script from 'next/script';

import { useClientReady } from '@/shared/lib/hooks/use-client-ready';

declare global {
  interface Window {
    zE?: (module: string, action: string, ...args: any[]) => void;
  }
}

type Props = {
  chatId: string;
};

export const Zendesk: React.FC<Props> = ({ chatId }) => {
  const ready = useClientReady();

  if (!ready) {
    return null;
  }

  return (
    <Script id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=${chatId}`} />
  );
};
