'use client';

import { useAppConfig } from '@/entities/app-config';
import { useScopedI18n } from '@/shared/locales/client';
import { CookieIcon, Button as NewButton } from '@/shared/ui';
import classNames from 'classnames';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { safeLocalStorage } from '@/shared/lib/safe-local-storage';

export const CookiesWarning = () => {
  const t = useScopedI18n('cookiesWarning');
  const [cookieConsent, setCookieConsent] = useState<boolean | null>(null);
  const {
    config: { cookiesWarning }
  } = useAppConfig();

  useEffect(() => {
    const storedCookieConsent = safeLocalStorage.getItem<boolean>('cookie_consent');
    setCookieConsent(storedCookieConsent === true);
  }, []);

  useEffect(() => {
    if (cookieConsent !== null) {
      safeLocalStorage.setItem('cookie_consent', cookieConsent);
    }
  }, [cookieConsent]);

  const handleAcceptCookies = () => {
    setCookieConsent(true);
  };

  return (
    <>
      {cookiesWarning?.warningText && cookieConsent === false && (
        <div
          className={classNames(
            'fixed bottom-5t left-0 right-0 z-top flex items-center justify-start gap-1 rounded-t-1 bg-mint-veryDark px-2 py-[16px] text-text-primary md:justify-center md:rounded-t-2',
            'flex-row md:bottom-0 md:h-6t md:gap-0'
          )}
        >
          <CookieIcon />
          <div className="typography-mobile-info ml-0 text-left md:typography-footnote md:ml-1 md:mr-2 md:text-center">
            <span>{cookiesWarning.warningText}</span>{' '}
            <Link href={'#'} className="underline underline-offset-4">
              {cookiesWarning?.linkText}
            </Link>
          </div>
          <NewButton
            size="md"
            variant="border"
            color="white"
            onClick={handleAcceptCookies}
            className="ml-auto !h-4 w-full min-w-[54px] !max-w-[54px] flex-shrink-0 !text-12 !font-normal md:ml-0 md:!h-[44px] md:min-w-[115px] md:max-w-[115px] md:!text-16 md:!font-bold"
          >
            {t('button')}
          </NewButton>
        </div>
      )}
    </>
  );
};
