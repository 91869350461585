import { gql } from '@apollo/client';

export const auth = gql`
  mutation Auth($password: String!, $deviceId: String!, $email: String, $phone: String) {
    auth(password: $password, deviceId: $deviceId, email: $email, phone: $phone) {
      success
      message
      accessToken
      error {
        message
        stack
      }
    }
  }
`;
