'use client';

import {
  EmailRegFormFieldNames,
  EmailRegFormValues
} from '@/features/registration/ui/email-registration-form';
import {
  PhoneRegFormFieldNames,
  PhoneRegFormValues
} from '@/features/registration/ui/phone-registration-form';
import { useCallback, useState } from 'react';
import { apolloClient, contextWithoutAuth } from '@/shared/lib/apollo/apolloBrowser';
import {
  registerUnconfirmedByEmail,
  registerUnconfirmedByPhone,
  registerUserByEmail,
  registerUserByPhone
} from '@/features/registration/lib/api';
import { useDeviceId } from '@/entities/device-id';
import {
  completeEmailConfirmation,
  completePhoneConfirmation,
  sendEmailConfirmation,
  sendPhoneConfirmation
} from '@/features/validation/lib/api';
import { useRouter } from 'next/navigation';
import { RoutesConfig } from '@/shared/routes/config';
import { useAuth } from '@/entities/auth';
import { ValidationType } from '@/features/validation/lib/use-validation';
import { deleteTrackingCookies, getTrackingTags } from '@/entities/utm';
import cookies from 'js-cookie';
import {
  sendRegistrationFailEvent,
  sendRegistrationSuccessEvent
} from '@/shared/lib/gtm/events/registration-events';
import { useUserAccess } from '@/entities/blocked-ip';
import { getBaseDomain } from '@/shared/lib';

type Data = EmailRegFormValues | PhoneRegFormValues;

type Stage = 'form' | 'confirmation';

export type RegistrationType = 'phone' | 'email';

type Registration = {
  handleSubmitForm: (formData: Data) => Promise<{ success: boolean; message?: string }>;
  handleRegUser: (method: RegistrationType, code: string) => Promise<string | undefined>;
  handleRegUnconfirmed: (
    method: RegistrationType,
    formData: Data
  ) => Promise<{ success: boolean; message?: string }>;
  handleValidateUnconfirmed: (
    method: ValidationType,
    validationData: ValidationData,
    code?: string
  ) => Promise<string | undefined>;
  stage: Stage;
  registrationData: Data | Record<string, never>;
  sendConfirmationCode: (
    method: RegistrationType,
    source?: string,
    withAuth?: boolean
  ) => Promise<void>;
  returnToRegistrationForm: () => void;
  countryCode: string;
  setCountryCode: (arg: string) => void;
  clearData: () => void;
  isUnconfirmedAllowed: boolean;
  authByPhoneRestricted?: boolean | null;
};

type RegisterUserResponse = {
  success: boolean;
  message: string;
  accessToken: string;
  error: {
    message: string;
    stack: string;
  } | null;
};

type RegistrationParams = {
  onRegisterExistingUser: () => void;
};

export type ValidationData = { phone: string | undefined } | { email: string | undefined };

const IS_UNCONFIRMED = false;

export const useRegistration = ({ onRegisterExistingUser }: RegistrationParams): Registration => {
  const [registrationData, setRegistrationData] = useState<Data | Record<string, never>>({
    RuleAgreement: true
  });
  const [stage, setStage] = useState<Stage>('form');
  const [countryCode, setCountryCode] = useState('');
  const { deviceId } = useDeviceId();
  const router = useRouter();
  const { login } = useAuth();
  const { authByPhoneRestricted } = useUserAccess();

  const sendConfirmationCode = async (
    method: RegistrationType,
    source?: string,
    withAuth?: boolean
  ) => {
    const auth = withAuth ? {} : contextWithoutAuth;
    const res = await apolloClient.mutate({
      mutation: method === 'email' ? sendEmailConfirmation : sendPhoneConfirmation,
      variables: { [method]: source },
      ...auth
    });

    const fieldName = method === 'email' ? 'emailCodeConfirmation' : 'phoneCodeConfirmation';
    const error = !res.data[fieldName].success && res.data[fieldName].error.message;

    if (error) {
      throw error;
    }
  };

  const handleRegUser = useCallback(
    async (method: RegistrationType, code: string) => {
      try {
        const response = await apolloClient.mutate({
          mutation: method === 'email' ? registerUserByEmail : registerUserByPhone,
          variables: {
            ...registrationData,
            deviceId,
            code,
            trackingTags: getTrackingTags()
          },
          ...contextWithoutAuth
        });

        const error =
          response?.data?.registerUserByEmail?.error?.message ||
          response?.data?.registerUserByPhone?.error?.message;

        if (error) {
          sendRegistrationFailEvent(error);
          return error;
        }

        const token =
          response?.data?.registerUserByPhone?.accessToken ||
          response?.data?.registerUserByEmail?.accessToken;

        if (token != null) {
          const domain = getBaseDomain(location.hostname);
          cookies.set('registered', 'true', { domain });
          await login(token);
          router.push(RoutesConfig.home);
          sendRegistrationSuccessEvent(response?.data?.user_id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [registrationData, deviceId, router, login]
  );

  const handleRegUnconfirmed = async (method: RegistrationType, formData: Data) => {
    try {
      const response = await apolloClient.mutate({
        mutation: method === 'email' ? registerUnconfirmedByEmail : registerUnconfirmedByPhone,
        variables: {
          ...formData,
          deviceId,
          trackingTags: getTrackingTags()
        },
        ...contextWithoutAuth
      });

      const token =
        response?.data?.registerUnconfirmedByPhone?.accessToken ||
        response?.data?.registerUnconfirmedByEmail?.accessToken;

      const error =
        response?.data?.registerUnconfirmedByPhone?.error?.message ||
        response?.data?.registerUnconfirmedByEmail?.error?.message;

      const userId =
        response?.data?.registerUnconfirmedByPhone?.user?.id ||
        response?.data?.registerUnconfirmedByEmail?.user?.id;

      const isNewUser =
        response?.data?.registerUnconfirmedByPhone?.isNewUser ??
        response?.data?.registerUnconfirmedByEmail?.isNewUser;

      sendRegistrationSuccessEvent(userId || '');

      if (error) {
        return { success: false, message: error };
      }

      if (token) {
        const domain = getBaseDomain(location.hostname);
        cookies.set('registered', 'true', { domain });
        const { email } = formData as EmailRegFormValues;
        const { phone } = formData as PhoneRegFormValues;
        setRegistrationData(formData);

        await login(token);

        if (!isNewUser) {
          onRegisterExistingUser();

          return { success: true };
        }

        sendConfirmationCode(method, method === 'email' ? email : phone, true);
        setStage('confirmation');
        deleteTrackingCookies();
      }
      return { success: true };
    } catch (e) {
      return { success: false, message: typeof e === 'string' ? e : 'Something went wrong' };
    }
  };

  const handleValidateUnconfirmed = async (
    method: ValidationType,
    validationData: ValidationData,
    code?: string
  ) => {
    try {
      const response = await apolloClient.mutate({
        mutation: method === 'email' ? completeEmailConfirmation : completePhoneConfirmation,
        variables: {
          ...validationData,
          code: code
        }
      });

      const error =
        response?.data?.registerUserByEmail?.error?.message ||
        response?.data?.registerUserByPhone?.error?.message;

      if (error) {
        return error;
      }
    } catch (e) {
      return typeof e === 'string' ? e : 'Something went wrong';
    }
  };

  const returnToRegistrationForm = () => {
    setStage('form');
  };

  const handleSubmitForm = async (formData: Data) => {
    try {
      if (!IS_UNCONFIRMED) {
        if (EmailRegFormFieldNames.Email in formData) {
          await sendConfirmationCode('email', formData[EmailRegFormFieldNames.Email]);
        }

        if (PhoneRegFormFieldNames.Phone in formData) {
          await sendConfirmationCode('phone', formData[PhoneRegFormFieldNames.Phone]);
        }
        setStage('confirmation');
      }
      setRegistrationData(formData);
      setStage('confirmation');
      return { success: true };
    } catch (e) {
      return { success: false, message: typeof e === 'string' ? e : 'Something went wrong' };
    }
  };

  const clearData = () => {
    setRegistrationData({ RuleAgreement: true });
  };

  return {
    handleRegUser,
    handleSubmitForm,
    sendConfirmationCode,
    returnToRegistrationForm,
    stage,
    registrationData,
    countryCode,
    setCountryCode,
    clearData,
    handleRegUnconfirmed,
    handleValidateUnconfirmed,
    isUnconfirmedAllowed: IS_UNCONFIRMED,
    authByPhoneRestricted
  };
};
