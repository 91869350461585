'use client';

import { useEffect, useState } from 'react';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import FormWrapper from '@/features/iam/ui/FormWrapper';
import {
  usePromo,
  EmailRegistrationForm,
  PhoneRegistrationForm,
  EmailRegFormValues,
  EmailRegFormFieldNames,
  PhoneRegFormFieldNames,
  PhoneRegFormValues
} from '@/features/registration';
import { ValidationForm } from '@/features/validation';
import { useRegistration } from '@/features/registration/lib/use-registration';
import { usePayment } from '@/features/wallet';
import { useAppConfig } from '@/entities/app-config';
import { useScopedI18n } from '@/shared/locales/client';

type Props = {
  onClose: () => void;
  setRegStage: (arg :'form' | 'confirmation' | null) => void
};

export const RegistrationContainer: React.FC<Props> = ({ onClose, setRegStage }) => {
  const promo = usePromo();
  const t = useScopedI18n('register');
  const { openDeposit } = usePayment();

  const {
    config: { registrationTypes }
  } = useAppConfig();
  const initialRegistrationType = registrationTypes[0] ?? 'email';
  const initialTab = initialRegistrationType === 'phone' ? t('phone') : t('email');

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);
  const {
    handleSubmitForm,
    stage,
    registrationData,
    handleRegUser,
    sendConfirmationCode,
    returnToRegistrationForm,
    countryCode,
    setCountryCode,
    clearData,
    isUnconfirmedAllowed,
    handleRegUnconfirmed,
    handleValidateUnconfirmed,
    authByPhoneRestricted
  } = useRegistration({ onRegisterExistingUser: onClose });

  const { push } = useRouter();
  const searchParams = useSearchParams();

  const handleReturn = () => {
    const regType = searchParams.get('type');
    push(`?registration=true&type=${regType}`);
    returnToRegistrationForm();
  };

  const onTabChange = (tab: string) => {
    setSelectedTab(tab);
    const regType = tab === t('email') ? 'email' : 'phone';
    push(`?registration=true&type=${regType}`);
    clearData();
  };

  const email = (registrationData as EmailRegFormValues)?.[EmailRegFormFieldNames.Email];
  const phone = (registrationData as PhoneRegFormValues)?.[PhoneRegFormFieldNames.Phone];

  useEffect(() => {
    if(!isUnconfirmedAllowed){
      setRegStage(stage)
    }
  }, [isUnconfirmedAllowed, setRegStage, stage]);

  return (
    <main className="flex flex-row justify-center">
      <div className="flex flex-col items-center">
        {stage === 'confirmation' ? (
          <ValidationForm
            handleSubmit={handleRegUser}
            email={email}
            phone={phone}
            disablePadding
            sendConfirmationCode={sendConfirmationCode}
            handleReturn={handleReturn}
            handleValidateUnconfirmed={handleValidateUnconfirmed}
            isUnconfirmedAllowed={isUnconfirmedAllowed}
            withSkip
            wide
            handleSkip={openDeposit}
          />

        ) : (
          <FormWrapper
            withoutPadding
            isMobileModalHeader
            onMobileArrowClick={onClose}
            title={t('fastRegister')}
            initialTab={initialTab}
            onTabChange={onTabChange}
            disablePadding
            hideIfSingleTab
          >
            {registrationTypes.includes('phone') && !authByPhoneRestricted && (
              <FormWrapper.Tab label={t('phone')}>
                <PhoneRegistrationForm
                  handleSubmitForm={handleSubmitForm}
                  countryCode={countryCode}
                  setCountryCode={setCountryCode}
                  registrationData={registrationData as PhoneRegFormValues}
                  promo={promo}
                  isUnconfirmedAllowed={isUnconfirmedAllowed}
                  handleRegUnconfirmed={handleRegUnconfirmed}
                />
              </FormWrapper.Tab>
            )}
            {registrationTypes.includes('email') && (
              <FormWrapper.Tab label={t('email')}>
                <EmailRegistrationForm
                  handleSubmitForm={handleSubmitForm}
                  isUnconfirmedAllowed={isUnconfirmedAllowed}
                  handleRegUnconfirmed={handleRegUnconfirmed}
                  registrationData={registrationData as EmailRegFormValues}
                  promo={promo}
                />
              </FormWrapper.Tab>
            )}
          </FormWrapper>
        )}
      </div>
    </main>
  );
};
