'use client';

import FormWrapper from '@/features/iam/ui/FormWrapper';
import { useScopedI18n } from '@/shared/locales/client';
import { AuthMutationVariables, EmailLoginForm } from '@/features/login';
import { useMutation } from '@apollo/client';
import { auth } from '@/features/login/lib/api';
import { useDeviceId } from '@/entities/device-id';
import { useCallback, useEffect, useState } from 'react';
import { contextWithoutAuth } from '@/shared/lib/apollo/apolloBrowser';
import { PhoneLoginForm } from '@/features/login/ui/phone-login-form';
import { useRouter, useSearchParams } from 'next/navigation';
import { RoutesConfig } from '@/shared/routes/config';
import { useAuth } from '@/entities/auth';
import { getDataTestId } from '@/shared/lib/get-data-testid';
import { sendLoginFailEvent } from '@/shared/lib/gtm/events/login-events';
import { useUserAccess } from '@/entities/blocked-ip';
import { RegistrationType } from '@/features/registration/lib/use-registration';

type Props = {
  registrationTypes: RegistrationType[];
  onLogin?: () => void;
  onClose?: () => void;
};

export const LoginContainer: React.FC<Props> = ({ registrationTypes, onLogin, onClose }) => {
  const t = useScopedI18n('login');
  const testIdAuthForm = getDataTestId({ page: 'login', nameForTargetBlock: 'form' });
  const router = useRouter();
  const { deviceId } = useDeviceId();
  const [authenticate, { data, reset, error }] = useMutation(auth);
  const { login } = useAuth();
  const { authByPhoneRestricted } = useUserAccess();

  const initialRegistrationType = registrationTypes[0] ?? 'email';
  const initialTab = initialRegistrationType === 'phone' ? t('byPhone') : t('byEmail');

  const [selectedTab, setSelectedTab] = useState<string>(initialTab);

  let prevPagePath;
  if (typeof window !== 'undefined' && window.document) {
    prevPagePath = window.document.referrer;
  }
  const prevPageLocalPath = prevPagePath && '/' + prevPagePath.split('/').slice(3).join('/');

  useEffect(() => {
    if (data?.auth?.error || error) {
      sendLoginFailEvent(data.auth.error.message);
      return;
    }
    if (data?.auth?.accessToken) {
      login(data.auth.accessToken).then(() => onLogin?.());
    }
  }, [data, login, router, error, prevPageLocalPath, onLogin]);

  const handleAuthenticate = useCallback(
    (variables: Partial<AuthMutationVariables>) => {
      return authenticate({ variables: { ...variables, deviceId }, ...contextWithoutAuth });
    },
    [authenticate, deviceId]
  );

  const onTabChange = (tab: string) => {
    setSelectedTab(tab);
    const loginType = tab === t('byEmail') ? 'email' : 'phone';
    router.push(`?login=true&type=${loginType}`);
    reset();
  };

  const loginError =
    data?.auth?.error?.message === 'No User found' ||
    data?.auth?.error?.message === 'Password is incorrect'
      ? t('errors.wrongLogOrPass')
      : data?.auth?.error?.message;

  return (
    <main className="flex flex-row justify-center">
      <div data-testid={testIdAuthForm} className="flex w-full flex-col items-center">
        <FormWrapper
          title={t('mainHeader')}
          onTabChange={onTabChange}
          disablePadding
          initialTab={authByPhoneRestricted ? t('byEmail') : initialTab}
          withoutPadding
          isMobileModalHeader
          onMobileArrowClick={onClose}
          hideIfSingleTab
        >
          {registrationTypes.includes('phone') && !authByPhoneRestricted && (
            <FormWrapper.Tab label={t('byPhone')}>
              <PhoneLoginForm
                loginError={loginError}
                authenticate={handleAuthenticate}
                reset={reset}
              />
            </FormWrapper.Tab>
          )}
          {registrationTypes.includes('email') && (
            <FormWrapper.Tab label={t('byEmail')}>
              <EmailLoginForm
                loginError={loginError}
                authenticate={handleAuthenticate}
                reset={reset}
              />
            </FormWrapper.Tab>
          )}
        </FormWrapper>
      </div>
    </main>
  );
};
