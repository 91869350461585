'use client';

import React, { FC, useEffect, useState } from 'react';

import { useScopedI18n } from '@/shared/locales/client';
import { Controller, useForm } from 'react-hook-form';
import { Button, TextInput } from '@/shared/ui';
import { LoginProps } from '@/features/login';
import { CustomPhoneInput } from '@/shared/ui/new-phone-input';
import Link from 'next/link';
import { RoutesConfig } from '@/shared/routes/config';
import { ErrorMessage } from '@/features/login/ui/error-message';
import { PhoneRegFormFieldNames } from '@/features/registration';
import {
  countries,
  detectCountryByNumber,
  excludeCountries
} from '@/shared/ui/new-phone-input/countries';
import {
  sendLoginDontHaveAccountEvent,
  sendLoginForgotPasswordEvent,
  sendLoginFormButtonSubmitEvent,
  sendLoginFormInputEvent,
  sendLoginFormPasswordInputEvent
} from '@/shared/lib/gtm/events/login-events';
import { updateTarget } from '@/shared/lib/helpers/parse-password';
import { Block } from '@/shared/ui/block';
import { useBlacklist } from '@/entities/blacklist';
import classNames from 'classnames';

export enum PhoneLoginFormFieldNames {
  Phone = 'phone',
  Password = 'password'
}

export type PhoneLoginFormValues = {
  [PhoneLoginFormFieldNames.Phone]: string;
  [PhoneLoginFormFieldNames.Password]: string;
};

const defaultValues = {
  [PhoneLoginFormFieldNames.Phone]: '',
  [PhoneLoginFormFieldNames.Password]: ''
};

export const PhoneLoginForm: FC<LoginProps> = ({ authenticate, loginError, reset }) => {
  const [lastAuthTryData, setLastAuthTryData] = useState<PhoneLoginFormValues>();
  const t = useScopedI18n('login');

  const onSubmit = async (data: PhoneLoginFormValues) => {
    setLastAuthTryData(data);
    authenticate({
      ...data,
      [PhoneLoginFormFieldNames.Phone]: data[PhoneLoginFormFieldNames.Phone]?.replace(/\D/g, '')
    });
  };

  const { control, handleSubmit, formState, watch } = useForm<PhoneLoginFormValues>({
    defaultValues,
    mode: 'onTouched'
  });

  const { phone, password } = watch();

  const { isValid, isSubmitted, errors } = formState;

  const handleSubmitButtonClick = () => {
    sendLoginFormButtonSubmitEvent('phone');
    handleSubmit(onSubmit);
  };

  useEffect(() => {
    if (
      loginError &&
      isSubmitted &&
      (lastAuthTryData?.phone !== phone || lastAuthTryData?.password !== password)
    ) {
      reset();
    }
  }, [
    phone,
    password,
    loginError,
    isSubmitted,
    lastAuthTryData?.phone,
    lastAuthTryData?.password,
    reset
  ]);

  const { countries: blacklistCountries, loading } = useBlacklist();

  return (
    <Block className="md:px-0 md:pb-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name={PhoneRegFormFieldNames.Phone}
          control={control}
          rules={{
            required: t('errors.required'),
            pattern: { value: /^[^_]+$/, message: t('errors.phone') },
            validate: (value) =>
              detectCountryByNumber(value).iso === countries[0].iso ? t('errors.phone') : true
          }}
          render={({ field, fieldState: { error, isDirty, isTouched } }) => {
            return (
              <CustomPhoneInput
                countries={excludeCountries({ countries, excludeCountries: blacklistCountries })}
                value={field.value}
                onChange={field.onChange}
                onInputStart={() => sendLoginFormInputEvent('phone')}
                name={field.name}
                errorMessage={((isDirty || isTouched) && error?.message) || undefined}
                onBlur={field?.onBlur}
              />
            );
          }}
        />
        <Controller
          name={PhoneLoginFormFieldNames.Password}
          control={control}
          rules={{ required: t('errors.required') }}
          render={({ field, fieldState: { error, isDirty, isTouched } }) => (
            <TextInput
              placeholder={t('fields.password')}
              type="password"
              name={field.name}
              onChange={(e) => {
                const updatedTarget = updateTarget(e);
                field.onChange({ ...e, target: updatedTarget });
              }}
              onInputStart={() => sendLoginFormPasswordInputEvent('phone')}
              value={field.value}
              errorMessage={((isDirty || isTouched) && error?.message) || undefined}
              onBlur={field.onBlur}
              className={classNames('!mb-0 md:min-h-[32px]')}
            />
          )}
        />
        <Link
          className="mt-1/2 block w-fit text-14 leading-[20px] text-primary underline md:mt-1 md:text-14 md:leading-16"
          onClick={() => sendLoginForgotPasswordEvent()}
          href={`?recovery=true`}
        >
          {t('forgotpass')}
        </Link>
        <div className="mt-2 w-full md:mt-4">
          {loginError && (
            <div className="mb-1 w-full rounded-1 bg-yellow-attention px-2 py-2 text-12 leading-16 text-text-warning md:mb-2t md:rounded-2 md:px-4 md:py-2 md:text-lg md:font-medium md:leading-[20px]">
              {loginError}
            </div>
          )}
          {(Object.keys(errors).length > 0 || loginError) && (
            <div className="leading-20 mb-3 mt-1 text-14 text-text-primary md:mb-2 md:mt-2">
              {t('errors.tryOnceMore')}{' '}
              <Link
                className="ml-1 text-primary underline md:ml-[6px]"
                onClick={() => sendLoginDontHaveAccountEvent()}
                href={`?registration=true&type=email`}
              >
                {t('errors.support')}
              </Link>
            </div>
          )}
          <Button
            name="submit"
            type="submit"
            size="lg"
            className="h-6 w-full text-12 md:text-18"
            onClick={handleSubmitButtonClick}
            disabled={!isValid || !!loginError}
          >
            {t('fields.submit')}
          </Button>
        </div>
      </form>
      <div className="leading-20 mt-1 text-14 text-text-primary md:mt-2">
        {t('noAccount')}{' '}
        <Link
          className="ml-1 text-primary underline"
          onClick={() => sendLoginDontHaveAccountEvent()}
          href={`?registration=true&type=email`}
        >
          {t('register')}
        </Link>
      </div>
    </Block>
  );
};
