import { useEffect, useState } from 'react';

export const useClientReady = () => {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setReady(true);
    }
  }, []);

  return ready;
};
