import cookies from 'js-cookie';

export enum UtmTag {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  btag = 'btag'
}

export const getTrackingTags = () => ({
  utmSource: cookies.get(UtmTag.utm_source),
  utmMedium: cookies.get(UtmTag.utm_medium),
  utmCampaign: cookies.get(UtmTag.utm_campaign),
  btag: cookies.get(UtmTag.btag)
});

export const deleteTrackingCookies = () => {
  cookies.remove(UtmTag.utm_source);
  cookies.remove(UtmTag.utm_medium);
  cookies.remove(UtmTag.utm_campaign);
  cookies.remove(UtmTag.btag);
};
