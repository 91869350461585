import { useState } from 'react';

export const useDateFilter = (afterChangeCallback?: () => void) => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const onDateChange = (start: Date | undefined, end: Date | undefined) => {
    end?.setHours(23, 59, 59, 999);
    setStartDate(start);
    setEndDate(end);
    afterChangeCallback?.();
  };

  const onClearDateRange = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    afterChangeCallback?.();
  };

  return {
    startDate,
    endDate,
    onDateChange,
    onClearDateRange
  };
};
