'use client';

import React, { useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { PasswordRecoveryScreen } from '@/features/password-recovery';
import { useAppConfig } from '@/entities/app-config';
import { useUserAccess } from '@/entities/blocked-ip';
import { LoginContainer } from '../login';
import { Modal } from '@/shared/ui';
import { RegistrationContainer } from '../register';

type ModalType = 'login' | 'registration' | null | 'recovery';

export const AuthContainer = () => {
  const searchParams = useSearchParams();
  const { authByPhoneRestricted } = useUserAccess();
  const [regStage, setRegStage] = useState<'form' | 'confirmation' | null>(null)

  const router = useRouter();
  const pathname = usePathname();
  const login = searchParams.get('login');
  const registration = searchParams.get('registration');
  const recovery = searchParams.get('recovery');

  const {
    config: { registrationTypes }
  } = useAppConfig();

  const computedModalType = useMemo(() => {
    if (login) return 'login';
    if (registration) return 'registration';
    if (recovery) return 'recovery';
    return null;
  }, [login, registration, searchParams]);

  const [modalType, setModalType] = useState<ModalType>(computedModalType || null);

  const clearParams = () => {
    const url = new URLSearchParams(searchParams.toString());

    url.delete('login');
    url.delete('registration');
    url.delete('recovery');
    url.delete('type');
    const updated = url.toString();
    router.push(`${pathname}${updated ? `?${updated}` : ''}`);
  };

  const handleClose = () => {
    setModalType(null);
    clearParams();
  };

  useEffect(() => {
    if (!computedModalType) {
      handleClose();
    }
  }, [computedModalType]);

  useEffect(() => {
    setModalType(computedModalType);
  }, [computedModalType, searchParams]);

  if (modalType === 'login' && Boolean(registrationTypes.length)) {
    return (
      <Modal
        hideCrossOnMobile
        onClose={handleClose}
        className={'m-2 mx-0 mb-3 bg-main md:bg-background-card'}
        backingClassName={
          'z-top3 bg-main !bg-opacity-100 md:!bg-black md:!bg-opacity-80 !items-start pt-4t md:pt-0 md:!items-center'
        }
      >
        <LoginContainer
          registrationTypes={registrationTypes}
          onLogin={handleClose}
          onClose={handleClose}
        />
      </Modal>
    );
  }

  if (modalType === 'registration' && Boolean(registrationTypes.length)) {
    return (
      <Modal
        hideCrossOnMobile
        onClose={handleClose}
        notClosable={regStage === 'confirmation'}
        className={'m-2 mx-0 mb-3 bg-main md:bg-background-card'}
        backingClassName={
          'z-top3 bg-main !bg-opacity-100 md:!bg-black md:!bg-opacity-80 !items-start pt-4t md:pt-0 md:!items-center'
        }
      >
        <RegistrationContainer onClose={handleClose} setRegStage={setRegStage} />
      </Modal>
    );
  }

  if (modalType === 'recovery' && Boolean(registrationTypes.length)) {
    return (
      <Modal onClose={handleClose}>
        <PasswordRecoveryScreen
          registrationTypes={registrationTypes}
          handleClose={handleClose}
          authByPhoneRestricted={authByPhoneRestricted}
        />
      </Modal>
    );
  }
};
