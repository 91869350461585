import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/apps/main/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/app/apps/main/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/apps/main/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/main/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"variable\":\"--font-roboto\",\"weight\":[\"400\",\"500\",\"700\"],\"display\":\"swap\",\"subsets\":[\"latin\",\"cyrillic\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/main/src/app/[locale]/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/apps/main/src/shared/lib/apollo/apolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleTagManager","YandexMetrics"] */ "/app/apps/main/src/shared/lib/gtm/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/main/src/shared/lib/toast/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Zendesk"] */ "/app/apps/main/src/shared/lib/zendesk/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["I18nProviderClient"] */ "/app/apps/main/src/shared/locales/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["AuthContainer"] */ "/app/apps/main/src/widgets/auth-container/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookiesWarning"] */ "/app/apps/main/src/widgets/cookies-banner/index.tsx");
