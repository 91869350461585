/**
 *
 * @param utcDate YYYY-MM-DD
 * @returns DD.MM.YYYY
 */

export const formatUTCDate = (utcDate: string, options: Intl.DateTimeFormatOptions = {}) => {
  const date = new Date(utcDate);
  return date.toLocaleDateString('ru-RU', options);
};
